import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Article from '~/src/components/Article';

import Block from '~/src/components/Block';
import Figure from '~/src/components/Figure';

export const frontmatter = {
  id: 'funding-societies',
  isWork: true,
  subtitle: 'Funding Societies',
  title: 'Risky fintech design, at scale.',
  date: '2019-01-01',
  cover: './fsCover.jpg',
  path: '/work/funding-societies/',
  excerpt: `In 2017 I worked with Funding Societies, then a just promising fintech
    startup in South East Asia (SEA), now the biggest money lender in the
    area, to scale their potential through the launch of their iOS and
    Android mobile apps, alongside an identity exploration.`,
  details: [
    {
      title: 'Responsibility',
      description: 'Product and Identity Design',
    },
    {
      title: 'Date',
      description: 'Mid 2017',
    },
    {
      title: 'Technology',
      description: 'Mobile UI/UX and Branding',
    },
  ],
};

export default props => (
  <Article {...props}>
    <Block mobilePull>
      <p>
        In 2017 I worked with Funding Societies, then a just promising fintech
        startup in South East Asia (SEA), now the biggest money lender in the
        area, to scale their potential through the launch of their iOS and
        Android mobile apps, alongside an identity exploration.
      </p>
    </Block>
    <Block align="right">
      <p>
        The project was incredibly challanging as they had a tight deadline of
        2.5 months, where we spent a bit of time figuring out how to implement
        their duo-brand for the same venture, in the same app: Modalku in
        Indonesia and Funding Societies in Singapore. We set to start
        researching the markets, doing low-fidelity prototypes - and led a
        successful design sprint.
      </p>
    </Block>

    <Block align="left" pull>
      <Figure
        fluid={props.data.ui_1}
        caption="The underlying goal was to reinforce their brand with a visual identity that made money-lending look less risky. "
      />
    </Block>

    <Block align="right" pull>
      <Figure
        fluid={props.data.ui_2}
        caption="Some of the early iOS designs featuring a fintech tone, 100% built on reusable components."
      />
    </Block>

    <hr />

    <Block full pull align="center">
      <h2>The challanges</h2>
    </Block>
    <Block align="left" pull>
      <p>
        When we started, Funding Societies only had a couple of old design
        wireframes that were no longer aligned to the company's goals. This
        meant that I had to start fresh, from that depressing blank canvas where
        everything starts.
      </p>
    </Block>
    <Block align="right">
      <p>
        The project was incredibly challanging as they had a tight deadline of
        2.5 months, where we spent a bit of time figuring out how to implement
        their duo-brand for the same venture, in the same app: Modalku in
        Indonesia and Funding Societies in Singapore. We set to start
        researching the markets, doing low-fidelity prototypes - and led a
        successful design sprint.
      </p>
    </Block>
  </Article>
);

export const query = graphql`
  query GatsbyImageLivelloReplenisher {
    ui_2: file(relativePath: { eq: "funding-societies/fsInterface.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 522, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ui_1: file(relativePath: { eq: "funding-societies/fsBranding.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 522, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
